<template>
  <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
    <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
      <!--Заголовок-->
      <b-card-title title-tag="h2" class="font-weight-bold mb-1 fill">
        Изменение пароля
      </b-card-title>
      <b-card-text class="mb-2"> Введите ваш новый пароль </b-card-text>

      <!--Форма-->
      <validation-observer v-slot="{ invalid }">
        <b-form
          class="auth-reset-password-form mt-2"
          method="POST"
          @submit.prevent
        >
          <!--Инпуты-->
          <BlInput
            name="password"
            label="Новый пароль"
            rules="required|min:8"
            placeholder="........"
            input-type="password"
            v-model="password"
          >
          </BlInput>
          <BlInput
            name="confirm"
            label="Подтверждение нового пароля"
            rules="required|confirmed:password|min:8"
            placeholder="........"
            input-type="password"
            v-model="cPassword"
          >
          </BlInput>

          <!--Кнопка-->
          <b-button
            block
            type="submit"
            variant="primary"
            @click="restorePassword()"
            :disabled="invalid"
          >
            Установить новый пароль
          </b-button>
        </b-form>
      </validation-observer>

      <p class="text-center mt-2">
        <b-link :to="{ name: 'login' }">
          <feather-icon icon="ChevronLeftIcon" /> Назад к входу
        </b-link>
      </p>
    </b-col>
  </b-col>
</template>

<script>
import "@/libs/custom-validate";
import api from "@/api";

import BlInput from "@/components/controls/BlInput";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'


import {
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
} from "bootstrap-vue";
import utils from '@/utils';

export default {
  name: "ForgotForm",
  components: {
    BlInput,
    BCol,
    BLink,
    BForm,
    BButton,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      invalid: "",
      restore_key: "",
      email: "",
      cPassword: "",
      password: "",
    };
  },
  computed: {},
  methods: {
    restorePassword() {
      const self = this;
      const restore_key = this.$route.query.restore_key;
      api.auth.restore(restore_key, this.password).then(response=>{
        const data = response.data;
        if (data && data.message){
          utils.notificate(self, data.message);
        }

      })
      .catch(err=>{
        const data = err.response.data;
        if (data && data.error){
          utils.notificateError(self, data.message, data.errorCode);
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {});
    },
  },
};
</script>

<style scoped>
.fill {
  margin-top: 100px;
}
</style>
