<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <ByLedLogo />

      <!--Контент слева-->
      <PictureComp>
        <b-img
          fluid
          src="@/assets/images/pages/reset-password-v2.svg"
          alt="Login V2"
        />
      </PictureComp>

      <!--Контент (справа)-->
      <ResetForm />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BRow } from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PictureComp from '../../../core/PictureComp.vue'
import ByLedLogo from '../../../core/ByLedLogo.vue'
import ResetForm from './ResetForm.vue'

export default {
  components: {
    ResetForm,
    ByLedLogo,
    PictureComp,
    BRow,
    BImg,

  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      restore_key: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
